$(document).ready(function () {
    // Setup offcanvas mobile navbar
    let navToggler = $(".navbar-toggler");
    let navCollapse = $(".navbar-collapse");
    let closeNavbar = $(".close-navbar");
    
    $(".open-store-tabs .nav-link").on("click", function () {
        console.log($(this).parent().index());
        openStoreActiveTab = $(this).parent().index();

        openStoreActiveImg.removeClass("active-img");

        openStoreActiveImg.eq(openStoreActiveTab).addClass("active-img");
    });

    // Prevent closing from click inside dropdown
    $(document).on("click", ".dropdown-menu", function (e) {
        e.stopPropagation();
    });

    // Close offcanvas navbar when click anywhere outside of the navbar collapsed
    $(document).on("click", function (event) {
        if (!$(event.target).closest(".navbar-toggler").length && !$(event.target).closest(".navbar-collapse").length) {
            $(".navbar-collapse").removeClass("show");
            $('body').removeClass("overlay");
        }
    });

    // When click on burger icon add class show
    let navTogglerClicked = false;

    navToggler.on("click", function () {
        navCollapse.toggleClass("show");
        $('body').toggleClass("overlay");
    });

    // When click on close button within the offcanvas navbar remove class show and hide the navbar
    closeNavbar.on("click", function () {
        navCollapse.removeClass("show");
        $('body').removeClass("overlay");
    });

    let openStoreActiveTab = 0;
    let openStoreActiveImg = $(".open-store-img img");

    /* Home Page */
    let servicesSlider = $('.loftry-services-slider');
    if (servicesSlider.length) {
        const loftryServicesSwiper = new Swiper(".loftry-services-slider", {
            // Optional parameters
            loop: false,
            slidesPerView: 3,
            spaceBetween: 50,

            // Navigation arrows
            navigation: {
                nextEl: ".thumb-next-nav",
                prevEl: ".thumb-prev-nav",
            },

            // Breakpoints
            breakpoints: {
                1200: {
                    slidesPerView: 3,
                },

                992: {
                    slidesPerView: 2,
                },

                0: {
                    slidesPerView: 1,
                },
            },
        });
    }

    /* /Home Page */

    /* FAQs Page */
    // Add class to card header for active card
    let faqCardHeader = $(".card-header");
    faqCardHeader.on("click", function () {
        let $this = $(this);

        if ($this.hasClass("opened")) {
            $this.removeClass("opened");
        } else {
            faqCardHeader.removeClass("opened");
            $this.addClass("opened");
        }
    });
    /* /FAQs Page */

    /* Blog Page */
    let reportsSlider = $('.reports-slider');

    if (reportsSlider.length) {
        const reportsSwiper = new Swiper(".reports-slider", {
            // Optional parameters
            loop: false,
            slidesPerView: 4,
            spaceBetween: 35,

            // Navigation arrows
            navigation: {
                nextEl: ".thumb-next-nav",
                prevEl: ".thumb-prev-nav",
            },

            // Breakpoints
            breakpoints: {
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 35,
                },

                992: {
                    slidesPerView: 3,

                },

                576: {
                    slidesPerView: 2,
                },

                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                }
            }
        });
    }
    /* /Blog Page */

    /* Start Script For Sticky Filtes Top Nav */
    let topNav = document.querySelector('.top-nav-filter');

    if (window.innerWidth > 992) {
        if (topNav) {
            window.addEventListener('scroll', function () {
                let currentPageScroll = window.pageYOffset;

                if (currentPageScroll > 400) {
                    topNav.classList.add('sticky-filter-nav');
                } else {
                    topNav.classList.remove('sticky-filter-nav');
                }
            });
        }
    }
    /* End Script For Sticky Filtes Top Nav */

    //To Hide Modal on Click Back Button
    //-----------
    $('.modal').on('shown.bs.modal', function () {
        var urlReplace = '#' + $(this).attr('id')
        history.pushState(null, null, urlReplace)
    })

    $(window).on('popstate', function () {
        $('.modal').modal('hide')
    })

    /* Start Script For Contact Form */
    // Toaster Options
    if (!toastr) {
        toastr.options = {
            'closeButton': true,
            'debug': false,
            'newestOnTop': false,
            'progressBar': true,
            'positionClass': 'toast-top-right',
            'preventDuplicates': false,
            'onclick': null,
            'showDuration': '300',
            'hideDuration': '1000',
            'timeOut': '5000',
            'extendedTimeOut': '1000',
            'showEasing': 'swing',
            'hideEasing': 'linear',
            'showMethod': 'fadeIn',
            'hideMethod': 'fadeOut',
        }
    }

    let formSubmitBtn = $('.submit-btn');

    // Validation Rules
    var emailReg = /^$|([\w\-\.]{2,}\@[a-zA-Z-0-9]{2,}\.[\w\-]{2,4})/;
    var numReg = /[0-9]/;

    // Start Detecting the click on submit button
    formSubmitBtn.on('click', function (e) {
        e.preventDefault();
        var $this = $(this).parents('form');
        var nameInput = $this.find('.name-input').val();
        var emailInput = $this.find('.email-input').val();
        var msgInput = $this.find('.msg-input').val();
        var phoneInput = $this.find('.phone-input').val();
        var cityInput = $this.find('.city-input').val();
        var checkedCategories = [];
        var checkedCheckbox = $('input[name="category"]:checked');

        checkedCheckbox.each(function () {
            var checked = ($(this).val());
            checkedCategories.push(checked);
        });

        // Choose the variable value based on condition
        var valid = true;

        // Check if name or phone is empty
        if (nameInput === '') {
            valid = false;
            // toastr.warning(trans.please_enter.name);
            toastr.warning("Lütfen adınızı giriniz.");
        }

        // Check if Email is empty
        else if (emailInput === '') {
            valid = false;
            toastr.warning("Lütfen e-postanızı yazın.");
        }

        // Check if the email is in correct format
        else if (!emailReg.test(emailInput)) {
            valid = false;
            // toastr.warning(trans.error.email);
            toastr.warning("Geçersiz e-posta!");
        }

        // Check if the phone number is valid
        else if (phoneInput && !numReg.test(phoneInput)) {
            valid = false;
            // toastr.warning(trans.error.mobile);
            toastr.warning("Geçersiz telefon numarası!");
        }

        // Check if message is empty
        else if (msgInput === '') {
            valid = false;
            toastr.warning("Lütfen isteğinizi yazın");
        }

        var $_data = {
            name: nameInput,
            email: emailInput,
            message: msgInput,
            phone: phoneInput,
            city: cityInput,
            categories: checkedCategories,
            _token: $('meta[name="csrf-token"]').attr('content'),
        }

        // If Valid
        if (valid) {
            formSubmitBtn.prop('disabled', true);
            return SaveContactMessage($this, $_data);
        }
    });

    // Send Ajax Request To save new Message in database.
    function SaveContactMessage($this, _data) {
        $.ajax({
            url: $this.attr('action'),
            method: 'POST',
            dataType: 'json',
            data: _data,
            beforeSend: function () {
                $this.find('.send').addClass('d-none')
                $this.find('.sending').removeClass('d-none')
            },
            success: function (data) {
                if (data.code === 402) {
                    toastr.error(data.data)
                } else {
                    window.location.href = '/thanks'
                    dataLayer.push({ 'event': 'form_submitted' })
                    $this[0].reset()
                    toastr.success(data.data)
                }
                $this.find('.send').removeClass('d-none')
                $this.find('.sending').addClass('d-none')
                $this.find(formSubmitBtn).prop('disabled', false)
            },
            error: function (data, error_status, error_message) {
                if (data.status === 429) {
                    // toastr.error(trans.too_many_attempts)
                    toastr.error("Çok fazla denendi. Lütfen daha sonra tekrar deneyiniz!")
                } else {
                    _data['error_status'] = data.status
                    _data['error_message'] = error_message
                    // toastr.error(trans.error_sending)
                    toastr.error("Mesaj gönderilirken bir hata oluştu, lütfen daha sonra tekrar deneyin.")
                    // ====================================================================
                }
            },
            complete: function () {
                $this.find('.send').removeClass('d-none')
                $this.find('.sending').addClass('d-none')
                formSubmitBtn.prop('disabled', false)
            },
        })
    }

    /* /End Script For Contact Form */
});

    // ================================================================
    // start subscribe functions
    // ================================================================
    $('#subscribe_btn').click(function (e) {
        e.preventDefault()
        var isValid = true
        if (!Validatation('email-subscribe', 'required', '', '')) {
            isValid = false
            toastr.warning("Lütfen e-postanızı yazın.");
        } else {
            if (!Validatation('email-subscribe', 'email', '', '')) {
                isValid = false;
                toastr.warning("Geçersiz e-posta!");
            }
        }
        if (isValid == false) {
            return false
        } else {
            $('#subscribe_form').submit()
        }
    })

    $(document).on('submit', '#subscribe_form', function (event) {
        var language = $('html').attr('lang');

        event.preventDefault()
        $('#subscribe_btn').prop('disabled', true);
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
        })
        var email = $(this).find('input[name=email]').val()
        $.ajax({
            url: '/email-subscribe',
            method: 'POST',
            data: {
                email: email,
                locale: language,
            },
            dataType: 'json',
            success: function (data) {
                $('#subscribe_btn').prop('disabled', false);
                if (data.error) {
                    var error_html = ''
                    // var errors = $.parseJSON(data.responseText);
                    var errors = data.error;
                    $.each(errors, function (key, value) {
                        error_html += '<div class="alert alert-danger">' + value + '</div>'
                        $('#email-subscribe-output').html(error_html)
                    })
                } else {
                    $('#email-subscribe-output').html('<div class="alert alert-success">' + data.success + '</div>')
                    $('#email-subscribe').val('')
                    toastr.success(data.success);
                }
            },
            error: function (data) {
                $('#subscribe_btn').prop('disabled', false);
                var error_html = ''
                var errors = $.parseJSON(data.responseText);
                console.log(errors.error);
                toastr.warning(errors.error);
                $.each(errors, function (key, value) {
                    error_html += '<div class="alert alert-danger">' + value + '</div>'
                    $('#email-subscribe-output').html(error_html)
                })
                $('#email-subscribe-output').html(error_html)
            },
        })
    })
    // ================================================================
    // start subscribe functions
    // ================================================================

    // ================================================================
    // start submit on enter
    // ================================================================
    $('.submit_on_enter').keydown(function (event) {
        // enter has keyCode = 13, change it if you want to use another button
        if (event.keyCode == 13) {
            this.form.submit()
            return false
        }
    })
    // ================================================================
    // end submit on enter

    /* Start Native Share */
    let shareData = {
        title: document.getElementsByTagName('title')[0].textContent,
        url: window.location.href,
    }

    const nativeShareBtns = document.querySelectorAll('.native-share');

    nativeShareBtns.forEach(nativeShareBtn => {
        nativeShareBtn.addEventListener('click', () => {
            navigator.share(shareData)
        });
    })
    /* End Native Share */