$(document).ready(function ($) {

    $Language = $('html').attr('lang');
    $loadingMsg = 'جاري التحقق ';
    $pleasewaitMsg = 'الرجاء الانتظار ';
    $fillThemeAllMsg = 'يرجى تعبئة كافة الحقول ';
    $pleaseEnterMsg = 'الرجاء ادخال ';
    $errorAddressErrorMsg = 'عنوان غير صالح ';
    $errorNumbersOnlyMsg = ' يجب أن يحتوي على أرقام فقط ';
    $errorMobileNumberMsg = 'رقم موبايل خاطئ, مثال : ';
    $errorEmailMsg = 'عنوان البريد الالكتروني خاطئ ';
    $validDateMsg = 'تاريخ البداية يجب أن يكون اقل من تاريخ النهاية ';
    $passwordsMismatch = 'كلمتي المرور غير متطابقتين ';
    $errorLessLengthMsg = ' كلمة المرور قصيرة, يرجى ادخال كلمة مرور من 4 محارف او اكثر ';
    $syriatelGsmMsg1 = 'عذرا، هذه الخدمة مخصصة لطلاب الجامعات والمعاهد السورية من زبائن سيريتل. للاستفادة من شببلينك  يرجى زيارة أقرب مركز خدمات سيريتل مصطحباً بطاقتك الجامعية وهويتك الشخصية لتحديث بياناتك والاستفادة من الخدمة. ';
    $syriatelGsmMsg2 = ' إن الرقم المرسل غير صحيح، يرجى التأكد من أن الرقم مفعل ومستفيد من خدمة شببلينك';
    if ($Language === 'en') {
        $loadingMsg = 'Loading ';
        $pleasewaitMsg = 'Please Wait ';
        $fillThemeAllMsg = 'Please Fill All Fields ';
        $pleaseEnterMsg = 'Please Enter ';
        $errorAddressErrorMsg = 'Invalid Address ';
        $errorNumbersOnlyMsg = 'numbers only ';
        $errorMobileNumberMsg = 'Invalid mobile No. etc : ';
        $errorEmailMsg = 'Invalid Email ';
        $validDateMsg = 'Start date must be smaller than End date ';
        $passwordsMismatch = 'two passwords dose\'t match ';
        $errorLessLengthMsg = ' Password should be 6 characters or more ';
        $syriatelGsmMsg1 = 'Sorry, this service is especially for Syrian universities and institutes students. To benefit from Shabablink, please visit the nearest Syriatel POS with the required university card and personal identity to update your data.';
        $syriatelGsmMsg2 = 'sorry! phone number you entered is incorrect, please check your number is activated and can benefit from Shabablink service';
    }
    $.urlParam = function (name) {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results !== null) {
            return results[1] || 0;
        }
    }
    $(".NumericOnly").keydown(function (event) {
        // Allow: backspace, delete, tab, escape, and enter
        if (event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 || event.keyCode == 13 ||
            // Allow: Ctrl+A
            (event.keyCode == 65 && event.ctrlKey === true) ||
            // Allow: home, end, left, right
            (event.keyCode >= 35 && event.keyCode <= 39)) {
            // let it happen, don't do anything
            return;
        }
        else {
            // Ensure that it is a number and stop the keypress
            if (event.shiftKey || (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105 )) {
                event.preventDefault();
            }
        }
    });


});
function loading(name, overlay) {
    $('body').append('<div id="overlay"></div><div id="preloader">' + name + '..</div>');
    if (overlay == 1) {
        $('#overlay').css('opacity', 0.3).fadeIn(function () {
            $('#preloader').fadeIn();
        });
        return false;
    }
    $('#preloader').fadeIn();
}

function unloading() {
    //   $('#preloader').fadeOut('fast',function(){ $('#overlay').fadeOut(); });
    $('#preloader').fadeOut('fast', function () {
        $('#overlay').fadeOut('fast', function () {
            $('#preloader').css({display: "none"})
        });
    });

}
function Validatation($field, $ValidateType, $Vars1, $Vars2) {
    var $fieldValue = $('#' + $field).val();
    var $errorDiv = $('#' + $field).parents('div.form-group');
    var $errorText = $('#' + $field).parents('div.form-group').find('p');
    var $labelText1 = $('#' + $field).parents('div.form-group').find('label').html();
    var $labelText = $labelText1;
    if ($Vars1 != '') {
        var $errorDivJJ = $('#' + $Vars1).parents('div.form-group');
        var $errorTextJJ = $('#' + $Vars1).parents('div.form-group').find('p');
        var $labelTextJJ1 = $('#' + $Vars1).parents('div.form-group').find('label').html();
        var $labelTextJJ = $labelTextJJ1;
    }


    if ($ValidateType == 'required') {
        if (($fieldValue.length < 1)) {
            $errorDiv.addClass("has-error");
            $errorText.html($pleaseEnterMsg + $labelText1);
            return false;
        }
        else {
            $errorDiv.removeClass("has-error");
            $errorText.html(' ');
            return true;
        }
    }
    if ($ValidateType == 'length') {
        if (($fieldValue.length < 4)) {
            $errorDiv.addClass("has-error");
            $errorText.text($errorLessLengthMsg );
            return false;
        }
        else {
            $errorDiv.removeClass("has-error");
            $errorText.html(' ');
            return true;
        }
    }
    if ($ValidateType == 'length2') {
        if (($fieldValue.length < 8 || $fieldValue.length > 14)) {
            $errorDiv.addClass("has-error");
            $errorText.text($syriatelGsmMsg2);
            return false;
        }
        else {
            $errorDiv.removeClass("has-error");
            $errorText.html(' ');
            return true;
        }
    }
    if ($ValidateType == 'geo') {
        var numReg = /^[+-]?(?!0\d)\d*(\.\d+)$/;
        if (!numReg.test($fieldValue)) {
            $errorDiv.addClass("has-error");
            $errorText.html('' + $labelText1 + $errorAddressErrorMsg);
            return false;
        }
        else {
            $errorDiv.removeClass("has-error");
            $errorText.html(' ');
            return true;
        }
    }
    if ($ValidateType == 'numeric') {
        var numReg = /[0-9]/;
        if (!numReg.test($fieldValue)) {
            $errorDiv.addClass("has-error");
            $errorText.html('' + $labelText1 + $errorNumbersOnlyMsg);
            return false;
        }
        else {
            $errorDiv.removeClass("has-error");
            $errorText.html(' ');
            return true;
        }
    }
    if ($ValidateType == 'email') {

        var $tmpMail = $fieldValue.split('@');
        var emailReg = /^([\w\-\.]{2,}\@[a-zA-Z-0-9]{2,}\.[\w\-]{2,4})/;
        if (( !emailReg.test($fieldValue) ) || ( $tmpMail[1] == 'nomail.com')) {
            $errorDiv.addClass("has-error");
            $errorText.html($errorEmailMsg);
//					$errorText.html(''+$labelText2[0]+' ');
            return false;
        }
        else {

            $errorDiv.removeClass("has-error");
            $errorText.html(' ');
            return true;
        }

    }
    if ($ValidateType == 'select') {
        if (($fieldValue == '') || ($fieldValue == null) || ($fieldValue == "X")) {
            $errorDiv.addClass("has-error");
            $errorText.html($pleaseEnterMsg + $labelText1);
            return false;
//					console.log("error");
        }
        else {
            $errorDiv.removeClass("has-error");
            $errorText.html(' ');
            return true;
        }
    }
    if ($ValidateType == 'date-two') {
        var $fieldValue2 = $('#' + $Vars1).val();

        var nowDate = new Date();
        var startDate = new Date($fieldValue);
        var endDate = new Date($fieldValue2);
        if ((startDate > endDate)) {
            if (startDate > endDate) {
                $errorDiv.addClass("has-error");
                $errorText.html($validDateMsg);
            }

            return false;
        }
        else {
            $errorDivJJ.removeClass("has-error");
            $errorTextJJ.html(' ');
            $errorDiv.removeClass("has-error");
            $errorText.html(' ');
            return true;
        }
    }
    if ($ValidateType == 'password') {
        var $fieldValue2 = $('#' + $Vars1).val();

        if ($fieldValue != $fieldValue2) {
            $errorDiv.addClass("has-error");
            $errorText.html($passwordsMismatch);
            return false;
        }
        else {
            $errorDivJJ.removeClass("has-error");
            $errorTextJJ.html(' ');
            $errorDiv.removeClass("has-error");
            $errorText.html(' ');
            return true;
        }
    }


}




